export const SET_COURSE = 'fetchCourse';
export const UPDATE_PASSWORD = 'updatePassword';
export const UPDATE_LOCALE = 'updateLocale';
export const SET_SESSION = 'fetchSession';
export const FETCH_COURSES = 'fetchCourses';
export const FETCH_SESSIONS = 'fetchSessions';
export const INVITE_TO_SESSION = 'inviteToSession';
export const FETCH_ORGANIZATIONS = 'fetchOrganizations';
export const FETCH_ORGANIZATION_EULA = 'fetchEula';
export const CHECK_ORGANIZATION_ACCESS = 'checkOrganizationAccess';
export const CHECK_ORGANIZATION_DOMAIN = 'checkOrganizationDomain';
export const FETCH_RESOURCES = 'fetchResources';
export const FETCH_BADGES = 'fetchBadges';
export const FETCH_LEADERBOARDS = 'fetchLeaderboards';
export const FETCH_FORUMTHREADS = 'fetchForumThreads';
export const FETCH_FORUMTHREADPOSTS = 'fetchForumThreadPosts';
export const FOLLOWING_THREAD = 'followThread';
export const SEND_THREAD_MESSAGE = 'sendThreadMessage';
export const DELETE_THREAD_MESSAGE = 'deleteThreadMessage';
export const CREATE_NEW_THREAD = 'createNewThread';
export const FETCH_FILE = 'fetchFile';
export const SEND_ACTIVITY_TRACE = 'setScromTrace';
export const FETCH_SIMPLE_ACTIVITY = 'fetchSimpleActivityData';
export const FILE_CONTENT = 'fetchContent';
export const CHECK_AUTH = 'checkAuth';
export const CALLBACK = 'callbackLogin';
export const LOGIN = 'login';
export const CUSTOM_AUTH_ACTION = 'customAuthAction';
export const LOGINFORM = 'loginForm';
export const LOGOUT = 'logout';
export const SEND_HELP_MESSAGE = 'sendHelpMessage';

const REFERER_URL = 'referer_url';

export const getUrlReferer = () => {
  const refererUrl = window.localStorage.getItem(REFERER_URL);
  if (refererUrl && refererUrl !== 'undefined') {
    return refererUrl;
  }
  return false;
};

export const saveUrlReferer = (url) => {
  window.localStorage.setItem(REFERER_URL, url);
};

export const destroyUrlReferer = () => {
  window.localStorage.removeItem(REFERER_URL);
};

export default { getUrlReferer, saveUrlReferer, destroyUrlReferer };

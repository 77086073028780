import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import organization from './modules/organization.module';
import session from './modules/sessions.module';
import course from './modules/courses.module';
import resources from './modules/resource.module';
import badges from './modules/badge.module';
import forum from './modules/forum.module';
import leaderboards from './modules/leaderboard.module';
import activity from './modules/activity.module';
import communication from './modules/communication.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    organization,
    session,
    course,
    resources,
    badges,
    forum,
    leaderboards,
    activity,
    communication,
  },
});

import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION_EULA,
  CHECK_ORGANIZATION_DOMAIN,
  CHECK_ORGANIZATION_ACCESS,
} from '@/store/actions.type';
import {
  SET_ORGANIZATION_ID,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
  SET_ORGANIZATION_EULA,
} from '@/store/mutations.type';
import ApiService from '@/common/api.service';
import { saveOrganization, saveOrganizations } from '@/common/organization.service';

const state = {
  organizations: [],
  organization: {},
  organizationId: null,
  organizationEula: null,
  organizationEulaTitle: null,
};

const getters = {
  organizations: () => state.organizations,
  organization: () => state.organization,
  organizationId: () => state.organizationId,
  organizationEula: () => state.organizationEula,
  organizationEulaTitle: () => state.organizationEulaTitle,
};

const actions = {
  [FETCH_ORGANIZATIONS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('front/v1/organizations')
        .then(({ data: response }) => {
          const { data } = response;
          const { organizations } = data;
          context.commit(SET_ORGANIZATIONS, organizations);
          resolve(response);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [CHECK_ORGANIZATION_ACCESS](context) {
    const { organizationId } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/organization/${organizationId}/check_access`)
        .then(({ data: response }) => {
          resolve(response);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [CHECK_ORGANIZATION_DOMAIN](context, params = {}) {
    return new Promise((resolve, reject) => {
      ApiService.query('public/check_domain', params)
        .then(({ data: response }) => {
          const { data } = response;
          const { organizations } = data;
          context.commit(SET_ORGANIZATIONS, organizations);
          context.commit(SET_ORGANIZATION, organizations[0]);
          context.commit(SET_ORGANIZATION_ID, organizations[0].id);
          resolve(response);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FETCH_ORGANIZATION_EULA](context, type) {
    const { organizationId } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.get(`public/organization/${organizationId}/${type}`)
        .then(({ data: response }) => {
          const { data } = response;
          const { content } = data;
          context.commit(SET_ORGANIZATION_EULA, { content, type });
          resolve(response);
        }).catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_ORGANIZATIONS](states, organizations) {
    states.organizations = organizations;
    saveOrganizations(organizations);
  },
  [SET_ORGANIZATION](states, organization) {
    states.organization = organization;
    states.organizationId = organization.id;
  },
  [SET_ORGANIZATION_ID](states, id) {
    const intId = parseInt(id, 10);
    states.organizationId = intId;
    const filteredOrganization = states.organizations
      .filter((organization) => organization.id === intId);
    if (filteredOrganization.length > 0) {
      [states.organization] = filteredOrganization;
      saveOrganization(states.organization);
    }
  },
  [SET_ORGANIZATION_EULA](states, data) {
    states.organizationEula = data.content;
    states.organizationEulaTitle = `${data.type}.modal.title`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

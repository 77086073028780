import { FETCH_BADGES } from '@/store/actions.type';
import { SET_BADGES } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  badges: {
    badges: [],
  },
};

const getters = {
  badges: () => state.badges,
};

const actions = {
  [FETCH_BADGES](context, sessionId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/badges?training_session=${sessionId}`)
        .then(({ data }) => {
          context.commit(SET_BADGES, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_BADGES](states, { data }) {
    const { badges } = data;
    states.badges = badges;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import { SET_COURSE, FETCH_COURSES } from '@/store/actions.type';
import {
  SET_CURRENT_COURSE,
  SET_COURSES,
  SET_RESOURCES,
  SET_CURRENT_SESSION_PROGRESS,
} from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  courses: [],
  course: null,
};

const getters = {
  course: () => state.course,
  courses: () => state.courses,
  coursesChapter: () => {
    const chapters = [];

    state.courses.forEach((course) => {
      let index = course.chapterBasicOrder;

      if (index === null) {
        index = 0;
      }
      if (!chapters[index]) {
        chapters[index] = [];
      }
      chapters[index].push(course);
    });

    return chapters.filter((x) => x !== undefined);
  },
};

const actions = {
  [FETCH_COURSES](context) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/${currentSession.id}/courses`)
        .then(({ data }) => {
          context.commit(SET_COURSES, data);
          context.commit(SET_RESOURCES, data);
          context.commit(SET_CURRENT_SESSION_PROGRESS, data.data.progress);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [SET_COURSE](context, courseId) {
    context.commit(SET_CURRENT_COURSE, null);
    return new Promise((resolve, reject) => {
      if (!courseId) {
        resolve();
      }
      const course = state.courses.find(
        (courseObject) => courseObject.id === courseId && courseObject.available,
      );
      if (course) {
        context.commit(SET_CURRENT_COURSE, course);
        resolve();
      }
      reject();
    });
  },
};

const mutations = {
  [SET_COURSES](states, { data }) {
    const { courses } = data;
    states.courses = courses;
  },
  [SET_CURRENT_COURSE](states, course) {
    states.course = course;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

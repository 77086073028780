import {
  FETCH_FORUMTHREADS,
  FETCH_FORUMTHREADPOSTS,
  SEND_THREAD_MESSAGE,
  DELETE_THREAD_MESSAGE,
  CREATE_NEW_THREAD,
  FOLLOWING_THREAD,
} from '@/store/actions.type';
import { SET_FORUMTHREADS, SET_THREADPOSTS, SET_FOLLOW_ACTION } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  forum: {
    threads: [],
    id: 0,
    threadCount: 0,
    privacyLevel: 0,
    unreadedPostCount: 0,
  },
  thread: {
    posts: [],
    id: 0,
    title: '',
    followed: false,
  },
};

const getters = {
  forum: () => state.forum,
  thread: () => state.thread,
};

const actions = {
  [FETCH_FORUMTHREADS](context, forumId) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/${currentSession.id}/forum/get?forum=${forumId}`)
        .then(({ data }) => {
          context.commit(SET_FORUMTHREADS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FETCH_FORUMTHREADPOSTS](context, threadId) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.get(`front/v1/${currentSession.id}/forum/thread?forum=${currentSession.forum}&thread=${threadId}`)
        .then(({ data }) => {
          context.commit(SET_THREADPOSTS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [SEND_THREAD_MESSAGE](context, params) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.post(`front/v1/${currentSession.id}/forum/post-message`, params)
        .then(({ data }) => {
          context.commit(SET_THREADPOSTS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [DELETE_THREAD_MESSAGE](context, params) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.post(`front/v1/${currentSession.id}/forum/delete-message`, params)
        .then(({ data }) => {
          context.commit(SET_THREADPOSTS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [CREATE_NEW_THREAD](context, params) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.post(`front/v1/${currentSession.id}/forum/new-thread`, params)
        .then(({ data }) => {
          context.commit(SET_FORUMTHREADS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FOLLOWING_THREAD](context, params) {
    const { currentSession } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.post(`front/v1/${currentSession.id}/forum/follow-thread`, params)
        .then(({ data }) => {
          context.commit(SET_FOLLOW_ACTION, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_FORUMTHREADS](states, { data }) {
    const { threads, infos } = data;
    states.forum.threads = threads;
    states.forum.threadCount = infos.threadCount;
    states.forum.privacyLevel = infos.privacyLevel;
    states.forum.unreadedPostCount = infos.unreadedPostCount;
    states.forum.id = infos.id;
  },
  [SET_THREADPOSTS](states, { data }) {
    const { posts, infos } = data;
    states.thread.posts = posts;
    states.thread.id = infos.id;
    states.thread.title = infos.title;
    states.thread.followed = infos.followed;
  },
  [SET_FOLLOW_ACTION](states, { data }) {
    const { follow } = data;
    states.thread.followed = follow;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

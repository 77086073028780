// eslint-disable-next-line import/prefer-default-export
export const colorShade = (col, amt) => {
  // eslint-disable-next-line no-param-reassign
  col = col.replace(/^#/, '');
  // eslint-disable-next-line no-param-reassign
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt]);

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};

export const isLight = (colorInput) => {
  let color = colorInput;

  color = +(`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`);

  // eslint-disable-next-line no-bitwise
  const r = color >> 16;
  // eslint-disable-next-line no-bitwise
  const g = (color >> 8) & 255;
  // eslint-disable-next-line no-bitwise
  const b = color & 255;

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  return hsp >= 127.5;
};
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` : null;
};

export const hexToRgbNumbers = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
};

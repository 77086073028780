import { FETCH_FILE, FETCH_RESOURCES, FILE_CONTENT } from '@/store/actions.type';
import { SET_RESOURCES, SET_RESOURCE_VIEWED } from '@/store/mutations.type';
import ApiService from '@/common/api.service';
import { getToken } from '@/common/jwt.service';
import { API_URL } from '@/common/config';

const state = {
  resources: null,
  resourceList: [],
};

const getters = {
  resources: () => state.resources,
};

const actions = {
  [FETCH_RESOURCES](context) {
    return new Promise((resolve, reject) => {
      const { currentSession } = context.rootGetters;
      ApiService.query(`front/v1/${currentSession.id}/resources`, {})
        .then(({ data }) => {
          context.commit(SET_RESOURCES, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [FETCH_FILE](context, params = {}) {
    const { session, resourceId } = params;
    context.commit(SET_RESOURCE_VIEWED, resourceId);
    return ApiService.query(`front/v1/resources/download/${session}/${resourceId}`, {})
      .then((data) => {
        const fileLink = document.createElement('a');
        fileLink.href = data.data;
        fileLink.setAttribute('download', '');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  },
  [FILE_CONTENT](context, params = {}) {
    const {
      session,
      resourceId,
      fileType,
    } = params;

    if (['SHAREPOINT_PPT', 'SHAREPOINT_EXCEL', 'SHAREPOINT_WORD'].includes(fileType)) {
      return (`${API_URL}/api/front/v1/resources/download/${session}/${resourceId}?token=${getToken()}`);
    }
    return ApiService.query(`front/v1/resources/download/${session}/${resourceId}`)
      .then((data) => {
        context.commit(SET_RESOURCE_VIEWED, resourceId);
        return data.data;
      });
  },
};

function computeFolder(currentFolder) {
  const unViewedResources = currentFolder.resources
    .filter((r) => r.watched === false && r.available === true);
  const directUnViewedResources = unViewedResources.length;
  // eslint-disable-next-line no-param-reassign
  currentFolder.unviewed = directUnViewedResources;
  let hierarchyUnViewedResources = directUnViewedResources;
  currentFolder.subFolders
    .filter((f) => f.available === true)
    .forEach((f) => {
      hierarchyUnViewedResources += computeFolder(f);
    });
  // eslint-disable-next-line no-param-reassign
  currentFolder.totalUnViewedResources = hierarchyUnViewedResources;
  return hierarchyUnViewedResources;
}

function extractResources(currentFolder) {
  currentFolder.resources.forEach((r) => state.resourceList.push(r));
  currentFolder.subFolders
    .forEach((f) => {
      extractResources(f);
    });
}

const mutations = {
  [SET_RESOURCES](states, { data }) {
    const { resources } = data;
    computeFolder(resources);
    state.resourceList = [];
    extractResources(resources);
    states.resources = resources;
  },
  [SET_RESOURCE_VIEWED](states, resourceId) {
    const resource = states.resourceList.find((f) => f.id === resourceId);
    if (resource && !resource.watched) {
      resource.watched = true;
      computeFolder(states.resources);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import { FETCH_LEADERBOARDS } from '@/store/actions.type';
import { SET_LEADERBOARDS } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  leaderboard: {
    leaderboard: [],
    user_position: 0,
    total_users: 0,
    user_is_present: false,
  },
};

const getters = {
  leaderboard: () => state.leaderboard,
};

const actions = {
  [FETCH_LEADERBOARDS](context, params = {}) {
    return new Promise((resolve, reject) => {
      const session = this.getters.currentSession.id;
      ApiService.query(`front/v1/leaderboard/${session}`, params)
        .then(({ data }) => {
          context.commit(SET_LEADERBOARDS, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_LEADERBOARDS](states, { data }) {
    const {
      leaderboard,
      userPosition,
      totalUsers,
      userIsPresent,
    } = data;
    states.leaderboard.leaderboard = leaderboard;
    states.leaderboard.user_position = userPosition;
    states.leaderboard.total_users = totalUsers;
    states.leaderboard.user_is_present = userIsPresent;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import { SEND_HELP_MESSAGE } from '@/store/actions.type';
import ApiService from '@/common/api.service';

const state = {
};

const getters = {
};

const actions = {
  [SEND_HELP_MESSAGE](context, params) {
    return ApiService.post('front/v1/communication/sendmessage', params);
  },
};

const mutations = {
};

export default {
  state,
  actions,
  mutations,
  getters,
};

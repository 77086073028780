export const SET_CURRENT_COURSE = 'setCourse';
export const SET_ORGANIZATION = 'setOrganization';
export const SET_ORGANIZATION_EULA = 'setEula';
export const SET_CURRENT_SESSION = 'setSession';
export const SET_CURRENT_SESSION_PROGRESS = 'setSessionProgress';
export const SET_COURSES = 'setCourses';
export const SET_LAST_SESSION = 'setLastSession';
export const SET_SESSIONS = 'setSessions';
export const SET_ORGANIZATION_ID = 'setOrganizationId';
export const FETCH_END = 'setResults';
export const FETCH_START = 'setLoading';
export const PURGE_AUTH = 'logOut';
export const SET_ORGANIZATIONS = 'setOrganizations';
export const SET_RESOURCES = 'setResources';
export const SET_RESOURCE_VIEWED = 'setResourceViewed';
export const SET_LEADERBOARDS = 'setLeaderboards';
export const SET_BADGES = 'setBadges';
export const SET_FORUMTHREADS = 'setForumThreads';
export const SET_THREADPOSTS = 'setThreadPosts';
export const SET_FOLLOW_ACTION = 'setFollowAction';
export const SET_LAUNCH_DATA = 'setSimpleActivityData';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_PORTAL = 'setPortal';
export const SET_LOGIN = 'setLogin';
export const SET_PASSWORD = 'setPassword';
export const SET_LOCALE = 'setLocale';

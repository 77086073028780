<template>
    <router-view>
      <span slot="progress">
        <vue-progress-bar />
      </span>
    </router-view>
</template>

<script>
export default {
  name: 'App',
  components: {},
  computed: {
    // ...mapGetters(['isAuthenticated', 'isLoading', 'hasTeamInvitation']),
  },
  watch: {
    $route(to) {
      const page = to.meta && to.meta.title ? `${to.meta.title} - ` : '';
      document.title = `${page} VTS Perform`;
    },
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$store.state.isLoading = false;
  },
  created() {
    this.axios.interceptors.request.use((config) => {
      this.$Progress.set(this.$Progress.get() + 0.0001);
      this.$Progress.increase(5);
      return config;
    },
    (error) => {
      this.$Progress.fail();
      Promise.reject(error);
    });

    this.axios.interceptors.response.use((response) => {
      this.$Progress.increase(5);
      return response;
    },
    (error) => {
      this.$Progress.fail();

      return Promise.reject(error);
    });

    // //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$store.state.isLoading = true;
    // //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    // // //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  },
};
</script>

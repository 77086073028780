<template>
  <div class="position-sticky">
    <template v-if="!checkDates">
      <div class="card-image" v-if="!session  && !small">
        <div class="d-flex align-items-center justify-content-center">
          <div style="position: absolute;" class="font-weight-bold d-flex">
            <div class="course-shadow"></div>
            <img v-if="object.thumbnail_square"
                 :src="getObjectThumbnail('course', orgId, object.id, object.thumbnail_square)"
                 class="cover-fit w-85px h-85px rounded-circle" alt="">
            <img v-else
                 class="cover-fit w-85px h-85px rounded-circle" alt=""
                 src="../assets/images/no_thumbnail.png">
            <div v-if="!locked" class="progress_text">
              <span class="font-weight-bold">{{ getProgress(object.progress) }}</span>%
            </div>
            <div v-else style="z-index: 1; background: rgba(0, 0, 0, 0.7);"
                 class="position-absolute h-100 w-100
                 rounded-circle d-flex align-items-center justify-content-center">
              <img src="@/assets/images/icons/Icon_Lock.svg"
                   class="lock" alt="">
            </div>
          </div>
          <svg class="donut donut__course donut__progress_course" viewBox="0 0 32 32">
            <circle class="donut__strokebackground_course"
                    cx="16" cy="16" r="14"></circle>
            <transition name="progress">
              <circle :key="object.progress"
                      class="donut__value donut__value__course" cx="16" cy="16" r="14"
                      :stroke-dashoffset="getDonutValue(object.progress)"></circle>
            </transition>
          </svg>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-center justify-content-center" style="margin-top: 0px">
          <div style="position: absolute; z-index: 1; font-size: 12px" class="font-weight-bold">
            {{ getProgress(object.progress) }}%</div>
          <svg class="donut donut__progress" viewBox="0 0 32 32">
            <circle class="donut__background" cx="16" cy="16" r="12"></circle>
            <circle class="donut__strokebackground" cx="16" cy="16" r="14"></circle>
            <transition name="progress">
              <circle :key="object.progress"
                    class="donut__value" cx="16" cy="16" r="14"
                    :stroke-dashoffset="getDonutValue(object.progress)"></circle>
            </transition>
          </svg>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center justify-content-center progress-value display-h5">
        <template>
          <template
            v-if="isInferiorToToday(object.start_date)&& !isInferiorToToday(object.due_date)">
            <div v-if="!session && !small" class="card-image">
              <div class="rounded-circle position-relative">
                <div class="course-shadow"></div>
                <img class="cover-fit w-90px h-90px rounded-circle"
                     v-if="object.thumbnail_square"
                     :src="getObjectThumbnail('course',
                     orgId,
                     object.id,
                     object.thumbnail_square)"
                     alt="">
                <img class="cover-fit w-90px h-90px"
                     v-else
                     :src="defaultThumbnail" alt="">
                <div v-if="!locked" class="progress_text">
                  <span class="font-weight-bold">{{ getProgress(object.progress) }}</span>%
                </div>
                <div v-else style="z-index: 1; background: rgba(0, 0, 0, 0.7);"
                     class="position-absolute h-100 w-100
                 rounded-circle d-flex align-items-center justify-content-center">
                  <img src="@/assets/images/icons/Icon_Lock.svg"
                       class="lock" alt="">
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-items-center justify-content-center"
                   style="margin-top: -25px">
                <div style="position: absolute; z-index: 1; font-size: 12px"
                     class="font-weight-bold">
                  {{ getProgress(object.progress) }}%</div>
                <svg class="donut donut__progress" viewBox="0 0 32 32">
                  <circle class="donut__background" cx="16" cy="16" r="12"></circle>
                  <circle class="donut__strokebackground" cx="16" cy="16" r="14"></circle>
                  <transition name="progress">
                    <circle :key="object.progress"
                            class="donut__value" cx="16" cy="16" r="14"
                            :stroke-dashoffset="getDonutValue(object.progress)"></circle>
                  </transition>
                </svg>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center justify-content-center"
                 style="margin-top: -15px">
              <div style="position: absolute; z-index: 1; font-size: 12px"
                   class="font-weight-bold">
                <img src="@/assets/images/icons/Icon_Lock.svg"
                     class="lock" alt=""></div>
              <svg class="donut donut__progress" viewBox="0 0 32 32">
                <circle class="donut__background" cx="16" cy="16" r="12"></circle>
                <circle class="donut__strokebackground" cx="16" cy="16" r="14"></circle>
                <transition name="progress">
                  <circle :key="object.progress"
                          class="donut__value" cx="16" cy="16" r="14"
                        :stroke-dashoffset="getDonutValue(object.progress)"></circle>
                </transition>
              </svg>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import HelperMixin from '@/mixins/helperMixin';

export default {
  name: 'ProgressDonut',
  mixins: [HelperMixin],
  props: {
    object: {},
    checkDates: {
      default: true,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    overlay: {
      default: false,
      type: Boolean,
    },
    session: {
      default: false,
      type: Boolean,
    },
    orgId: {
      default: '-1',
      type: String,
    },
    locked: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getDonutSize() {
      return this.small ? 4 : 8;
    },
    getThickness() {
      return this.small ? 15 : 10;
    },
    getDonutClass() {
      let donutClass = '';

      if (this.small) {
        donutClass += 'sm ';
      }

      if (this.overlay) {
        donutClass += 'overlay ';
      }

      if (this.session) {
        donutClass += 'overlay_session ';
      }

      return donutClass;
    },
  },
  methods: {
    getDonutValue(progress) {
      if (progress !== null) {
        return (((progress / 100) * 90 + 90));
      }
      return 90;
    },
    getProgress(progress) {
      if (progress !== null) {
        return progress;
      }
      return 0;
    },
  },
  data() {
    return {
      defaultThumbnail: '../assets/images/no_thumbnail.png',
    };
  },
};
</script>
<style scoped>
.lock{
  width: 3rem;
  height: 3rem;
}
</style>

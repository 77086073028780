import moment from 'moment';
import { PERFORM_URL } from '@/common/config';
import { FETCH_SESSIONS, SET_COURSE, SET_SESSION } from '@/store/actions.type';
import i18n from '@/i18n';
import md5 from 'md5';
import {
  colorShade,
  hexToRgb,
  hexToRgbNumbers,
  isLight,
} from '@/common/color.helper';
import store from '@/store';

moment.locale(i18n.locale);

export default {
  data() {
    return {
      sessionFilter: null,
      sessionQuery: null,
    };
  },
  methods: {
    setOrgCSS(reset = false) {
      let actionColor;
      let primaryTextColor;
      let primaryBackgroundColor;
      let additiveBgColor;
      if (reset || !this.organization) {
        actionColor = '#00B3A4';
        primaryTextColor = '#ffffff';
        primaryBackgroundColor = '#000000';
      } else if (this.organization) {
        actionColor = this.organization.colors.actionColor ?? '#00B3A4';
        primaryTextColor = this.organization.colors.primaryTextColor ?? '#ffffff';
        primaryBackgroundColor = this.organization.colors.primaryBackgroundColor;
        additiveBgColor = this.organization.colors.additiveBackgroundColor;
      }
      document.documentElement.style.setProperty('--primary',
        hexToRgb(actionColor));
      this.$Progress.setColor(hexToRgb(actionColor));
      document.documentElement.style.setProperty('--primary-numbers',
        hexToRgbNumbers(actionColor));
      document.documentElement.style.setProperty('--primary-darker',
        hexToRgb(colorShade(actionColor, -20)));
      document.documentElement.style.setProperty('--primary-lighter',
        hexToRgb(colorShade(actionColor, +20)));
      document.documentElement.style.setProperty('--secondary',
        hexToRgb(primaryTextColor));
      document.documentElement.style.setProperty('--secondary-lighter',
        hexToRgb(colorShade(primaryTextColor, +20)));
      document.documentElement.style.setProperty('--secondary-darker',
        hexToRgb(colorShade(primaryTextColor, -20)));

      document.documentElement.style.setProperty('--background',
        hexToRgb(primaryBackgroundColor));
      if (isLight(primaryBackgroundColor)) {
        document.documentElement.style.setProperty('--background-transparent', `${colorShade(primaryBackgroundColor, -20)}CC`);
      } else {
        document.documentElement.style.setProperty('--background-transparent', `${colorShade(primaryBackgroundColor, +20)}CC`);
      }

      document.documentElement.style.setProperty('--secondary-background', hexToRgb(additiveBgColor));
    },
    handleSessionClick(sessionId) {
      this.animateCSS('.card-last-session', 'fadeOutUp', 'slower');
      Promise.all([
        store.dispatch(SET_SESSION, null),
        store.dispatch(SET_COURSE, null),
      ]).then(() => this.$router.push({ name: 'SessionView', params: { sessionId } })).catch(() => {});
    },
    removeClassStartsWith: (node, className) => {
      [...node.classList].forEach((v) => {
        if (v.startsWith(className)) {
          node.classList.remove(v);
        }
      });
    },
    animateCSS(element, animation, speed = null, delay = null, prefix = 'animate__') {
      return new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const speedName = `${prefix}${speed}`;
        const delayName = `${prefix}delay-${delay}`;
        const node = document.querySelector(element);
        if (!node) return;
        this.removeClassStartsWith(node, prefix);

        node.classList.add(`${prefix}animated`, animationName);

        if (speed) {
          node.classList.add(speedName);
        }

        if (delay) {
          node.classList.add(delayName);
        }

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
      });
    },
    getSessionParams() {
      let params = {};

      if (this.sessionFilter) {
        params = { ...params, status: this.sessionFilter };
      }

      if (this.sessionQuery) {
        params = { ...params, query: this.sessionQuery };
      }

      return params;
    },
    getObjectThumbnail(objectType, organizationId, objectId, thumbnail) {
      return `${PERFORM_URL}/object/objectThumbnail/${objectType}/${organizationId}/${objectId}/${thumbnail}`;
    },
    getBadgeThumbnail(badgeId) {
      return `${PERFORM_URL}/badge/badgeThumbnail/${badgeId}`;
    },
    getOrganizationWallpaper(organizationId, vertical = false) {
      const direction = vertical ? 'vertical' : 'horizontal';

      return `${PERFORM_URL}/img/organization/${organizationId}/background/${direction}`;
    },
    getOrganizationLogo(organizationId) {
      return `${PERFORM_URL}/img/organization/${organizationId}/background/logo`;
    },
    isMobileOrTablet() {
      return window.screen.width <= 1200;
    },
    isMobile() {
      return window.screen.width <= 760;
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY');
    },
    formatDateFull(date) {
      moment.locale(i18n.locale);
      return moment.utc(date, 'YYYY-MM-DD h:mm:ss').local().format('LLLL');
    },
    padLeft(_n, width) {
      const z = '0';
      const n = `${_n}`;
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    formatSeconds(value) {
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value / 60) % 60);
      const seconds = value % 60;
      return `${this.padLeft(hours, 2)}:${this.padLeft(minutes, 2)}:${this.padLeft(seconds, 2)}`;
    },
    formatPrivacyLevel(name, privacyLevel) {
      const names = name.split('|');
      const firstName = names[0];
      const lastName = names[1];
      switch (privacyLevel) {
        case 1: return `${firstName} ${lastName[0]}.`;
        case 2: return `${firstName[0]}. ${lastName[0]}.`;
        case 3: return `${firstName[0]}${lastName[0]}`;
        default: return `${firstName} ${lastName}`;
      }
    },
    isInferiorToToday(date) {
      if (!date) {
        return false;
      }
      const sessionDate = new Date(date);
      const now = new Date();
      return sessionDate <= now;
    },
    filterSession(filter) {
      if (this.sessionFilter === filter) {
        this.sessionFilter = null;
      } else {
        this.sessionFilter = filter;
      }

      let params = {};

      if (this.sessionFilter) {
        params = { ...params, status: this.sessionFilter };
      }

      if (this.sessionQuery) {
        params = { ...params, query: this.sessionQuery };
      }

      this.$store.dispatch(FETCH_SESSIONS, params).then(() => {
        this.$Progress.finish();
      });
    },
    handleSessionSearch() {
      const params = this.getSessionParams();

      this.$store.dispatch(FETCH_SESSIONS, params).then(() => {
        this.$Progress.finish();
      });
    },
    getGravatarURL(email) {
      return `https://s.gravatar.com/avatar/${md5(email)}?s=150&d=404`;
    },
    validateEmail(email) {
      return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    checkGravatar(email) {
      if (this.validateEmail(email)) {
        const xhr = new XMLHttpRequest();
        xhr.open('HEAD', this.getGravatarURL(email), false);
        xhr.send();
        if (xhr.status === 200) {
          return true;
        }
      }
      return false;
    },
    validatePassword(password) {
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^/&*-]).{7,}$/;
      return regex.test(password);
    },
    blockedText(condition) {
      return this.$t('resource.label.access.condition') + condition;
    },
  },
};

import { FETCH_SIMPLE_ACTIVITY, SEND_ACTIVITY_TRACE } from '@/store/actions.type';
import { SET_LAUNCH_DATA } from '@/store/mutations.type';
import ApiService from '@/common/api.service';

const state = {
  launchData: '',
};

const getters = {
  launchData: () => state.launchData,
};

const actions = {
  [FETCH_SIMPLE_ACTIVITY](context) {
    const { organizationId, currentSession, course } = context.rootGetters;
    return new Promise((resolve, reject) => {
      ApiService.query(`front/v1/courses/simple/launchdata?orgId=${organizationId}&sessionId=${currentSession.id}&course=${course.id}`)
        .then(({ data }) => {
          context.commit(SET_LAUNCH_DATA, data);
          resolve(data);
        }).catch((data) => {
          reject(data);
        });
    });
  },
  [SEND_ACTIVITY_TRACE](context, params) {
    const { organizationId, launchData, currentSession } = context.rootGetters;
    const {
      glue,
      scenarioId,
      gameId,
    } = launchData;
    return ApiService.post(`front/v1/courses/save/${glue}/${scenarioId}/${currentSession.attendee}?organizationId=${organizationId}&gameId=${gameId}`, params);
  },
};

const mutations = {
  [SET_LAUNCH_DATA](states, { data }) {
    states.launchData = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

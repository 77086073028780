const ID_ORGANIZATION_KEY = 'ORGANIZATION';
const ID_ORGANIZATIONS_KEY = 'ORGANIZATIONS';

export const getOrganization = () => {
  const organization = window.localStorage.getItem(ID_ORGANIZATION_KEY);
  if (organization && organization !== 'undefined') {
    return JSON.parse(organization);
  }
  return false;
};

export const getOrganizations = () => {
  const organizations = window.localStorage.getItem(ID_ORGANIZATIONS_KEY);
  if (organizations && organizations !== 'undefined') {
    return JSON.parse(organizations);
  }
  return false;
};

export const saveOrganization = (organization) => {
  window.localStorage.setItem(ID_ORGANIZATION_KEY, JSON.stringify(organization));
};

export const destroyOrganization = () => {
  window.localStorage.removeItem(ID_ORGANIZATION_KEY);
};

export const saveOrganizations = (organizations) => {
  window.localStorage.setItem(ID_ORGANIZATIONS_KEY, JSON.stringify(organizations));
};

export const destroyOrganizations = () => {
  window.localStorage.removeItem(ID_ORGANIZATIONS_KEY);
};
